<script>
export default {
   

    data() {
        return {
            options: [
                { item: 'A', name: 'Option A' },
                { item: 'B', name: 'Option B' },
                { item: 'C', name: 'Option C' },
                { item: "D", name: 'Option D' }
            ],
        };
    },

    created() {

    },
    props: {
        id: {
            required: true,
            type: String,
        },
        name : {
            required: true,
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            default: false
        },
        label: {

        },
        error: [],
        value: {}
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e);
        },
        // handleEnter() {
        //     if (this.onEnter) {
        //         this.onEnter();
        //     }
        // }
    },
    computed: {
        inputState() {
            if (this.error) {
                if (this.error.length > 0) {
                    return false;
                }
            }
            return null;
        }
    }
};
</script>

<template>
    <div class ="d-flex">
        <label for="input-live" class="mb-0">{{ label }}:</label>
        <b-form-checkbox
            class="ml-2"
            :id="id"
            :name="name"
            :state="inputState"
            :checked="value"
            value="business"
            unchecked-value="individual"
            @input="handleInput"
            >
            Business
        </b-form-checkbox>
        <!-- <b-form-input
          :id="id"
          :type="type"
          :name="name"
          :value="value"
          @input="handleInput"
          :state="inputState"
          :placeholder="placeholder"
          :disabled="disabled"
          trim
        >
        </b-form-input> -->
        <b-form-invalid-feedback id="input-live-feedback" :state="false" v-if="error && error.length > 0">
            {{ error[0] }}
        </b-form-invalid-feedback>
    </div>
</template>
